import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroArea from "@components/introarea/layout-2";
import BoxSection from "@components/BoxSection/layout-three/layout-2";
import LocalizeGrid from "@components/localize-grid/layout-1";
import HowWeWork from "@containers/global/how-we-work";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/translation-quote-form";

const MarketingTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Premium Marketing Localization Services by Andovar"
        description="Marketing Localization Services - Create customized experiences for audiences around the world with localized marketing and communications messages."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["marketing-translation-page-header"]} />
        <QuoteForm />
        <FeatureGrid data={content["marketing-translation-features"]} />
        <IntroArea data={content["marketing-transl-intro"]} />
        <BoxSection
          layout={2}
          data={content["market-industries-translation"]}
        />
        <LocalizeGrid data={content["marketing-solutions-feature"]} />
        <HowWeWork layout={2} data={content["howwework"]} />
        <CaseStudy data={caseStudiesData} />;
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query marketingTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "marketing-translation" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

MarketingTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MarketingTranslationPage;
